import "./Slide.scss";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import immagineoomen from "../../assets/pictures/portfolio-oomen.png";
import immagineschirru from "../../assets/pictures/portfolio-card.png";
import foodBlog from "../../assets/pictures/foodBlog.png";
import bandsite from "../../assets/pictures/bandsiteAPI.png";
// import React, { useState } from "react";
import { useTranslation } from "react-i18next";
function Slide() {
  // function alert website undercostruction

  function alertWebsiteUnderCostruction() {
    console.log("this website is undercostruction");
    alert("this website is undercostruction");
  }
  // import carousel code here
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const { t } = useTranslation("global");
  return (
    <div id="portfolio">
      <h1 className="portfolio-title">Portfolio</h1>
      <Carousel responsive={responsive} className="carousel">
        <div className="card">
          <div className="image-wrapper">
            <img src={immagineschirru} alt="product" />
          </div>
          <div className="wrapper-card-info">
            <h3>CANTINA AGRICOLA SCHIRRU</h3>
            <p className="cards-box__paragraph">{t("wine.message")}</p>

            <a
              href="https://azienda-agricola-schirru.netlify.app/"
              target="_blank"
              rel="noreferrer">
              <button className="button-card">WEBSITE</button>
            </a>
          </div>
        </div>
        <div className="card">
          <div className="image-wrapper">
            <img src={immagineoomen} alt="product" />
          </div>
          <div className="wrapper-card-info">
            <h3>OOMEN WEB SERVICES</h3>
            <p className="cards-box__paragraph">{t("startup.message")}</p>
            <p>
              <a
                href="https://www.oomen-webservices.com"
                target="_blank"
                rel="noreferrer">
                <button className="button-card">WEBSITE</button>
              </a>
            </p>
          </div>
        </div>
        <div className="card">
          <div className="image-wrapper">
            <img src={foodBlog} alt="product" />
          </div>
          <div className="wrapper-card-info">
            <h3>BLOG DOM MANIPULATION</h3>
            <p className="cards-box__paragraph filter-blur">
              Is a startup who allow customers to sell their digital products
              online with instant payment.
            </p>
            <p>
              <a
                href="https://github.com/DavideSchirru17"
                target="_blank"
                rel="noreferrer">
                <button className="button-card undercostruction">GITHUB</button>
              </a>
            </p>
          </div>
        </div>
        <div className="card">
          <div className="image-wrapper">
            <img src={bandsite} alt="product" />
          </div>
          <div className="wrapper-card-info">
            <h3>BAND SITE: using APIs, heroku </h3>
            <p className="cards-box__paragraph filter-blur">
              Is a startup who allow customers to sell their digital products
              online with instant payment.
            </p>
            <p>
              <a
                href="https://davideschirru17.github.io/bandsite-js-class-API-heroku/"
                target="_blank"
                rel="noreferrer">
                <button className="button-card undercostruction">
                  GITHUB pages
                </button>
              </a>
            </p>
          </div>
        </div>
        <div className="card">
          <div className="image-wrapper">
            <img src={immagineoomen} alt="product" className="filter-blur" />
          </div>
          <div className="wrapper-card-info">
            <h3>UNDER CONSTRUCTION</h3>
            <p className="cards-box__paragraph filter-blur">
              Is a startup who allow customers to sell their digital products
              online with instant payment.
            </p>
            <p>
              {/* <a> */}
              <button
                onClick={alertWebsiteUnderCostruction}
                className="button-card">
                WEBSITE
              </button>
              {/* </a> */}
            </p>
          </div>
        </div>
        <div className="card">
          <div className="image-wrapper">
            <img src={immagineoomen} alt="product" className="filter-blur" />
          </div>
          <div className="wrapper-card-info">
            <h3>UNDER CONSTRUCTION</h3>
            <p className="cards-box__paragraph filter-blur">
              Is a startup who allow customers to sell their digital products
              online with instant payment.
            </p>
            <p>
              {/* <a> */}
              <button
                onClick={alertWebsiteUnderCostruction}
                className="button-card">
                WEBSITE
              </button>
              {/* </a> */}
            </p>
          </div>
        </div>
      </Carousel>
      ;
    </div>
  );
}

export default Slide;

import { FaUser, FaLock } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./Form.scss";

// VARIABILI URL
const baseUrl = "http://localhost:8080";
const signupUrl = `${baseUrl}/signup`;
const loginUrl = `${baseUrl}/login`;
function Form() {
  // const [isSignedUp, setIsSignedUp] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [isLoginError, setIsLoginError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  // HANDLE THE SIGN UP AXIOS

  // HANDLE LOGIN AXIOS

  return (
    <div className="form-display">
      <form className="form" action="">
        <h1 className="form__title">Login</h1>
        <div className="input-box">
          <input type="text" placeholder="Username" required />
          <FaUser className="icon" />
        </div>
        <div className="input-box">
          <input type="text" placeholder="Password" />
          <FaLock className="icon" />
        </div>
        <div className="remember-forgot">
          <label>
            <input type="checkbox" /> Remember me
          </label>
          <a href="#">Forgot password?</a>
        </div>
        <button type="submit">Login</button>
        <div className="register-link">
          <p>Don't have an account?</p>
          <a>Register</a>
        </div>
      </form>
    </div>
  );
}

export default Form;

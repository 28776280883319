import "./Footer.scss";
import email from "../../assets/icons/e-mail.png";
import arrowup from "../../assets/icons/up-arrow.svg";
import { useTranslation } from "react-i18next";
function Footer() {
  let buttonUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { t } = useTranslation("global");
  return (
    <footer id="contacts" className="footer">
      <div className="botton-up" onClick={buttonUp}>
        <div>
          <img className="botton-up__icon" src={arrowup} alt="icon" />
        </div>
      </div>
      <section className="footer__wrapper">
        <div className="footer__box-contact-info">
          <div>{t("contattaci.message")}</div>
          <div>
            <a href="mailto:schirrudavide@icloud.com">
              <img className="email" src={email} alt="email" />
            </a>
          </div>
        </div>
        <div className="nav-list">
          <div className="nav-list__item ">{t("carrers.message")}</div>
          <div>
            <p className="nav-list__item">© 2024 Oomen Web Services</p>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;

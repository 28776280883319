import london from "../../assets/videos/london.mp4";
import Teaching from "../../components/Teaching/Teaching";
import "./Main.scss";
// import portfolio from "../../assets/pictures/portfolio-card.png";
// import pictureoomen from "../../assets/pictures/portfolio-oomen.png";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Main() {
  useEffect(() => {
    const initSlider = () => {
      const imageList = document.querySelector(".cards-box__products");
      const slideButtons = document.querySelectorAll(
        ".slider-wrapper .slide-button"
      );

      if (imageList) {
        slideButtons.forEach((button) => {
          button.addEventListener("click", () => {
            const direction = button.id === "prev-slide" ? -1 : 1;
            const scrollAmount = imageList.clientWidth * direction;
            imageList.scrollBy({ left: scrollAmount, behavior: "smooth" });
          });
        });
      }
    };

    // Delay the initialization to ensure the DOM is fully loaded
    setTimeout(initSlider, 0);
  }, []);

  const { t, i18n } = useTranslation("global");
  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <div id="home" className="websitename">
        <h1 className="websitename__title">
          <span className="websitename__span">OOMEN </span>
          {t("services.message")}
        </h1>
        <div className="translation-button">
          <button
            onClick={() => handleChangeLanguage("en")}
            className="translation-button__eng">
            EN
          </button>
          <button
            onClick={() => handleChangeLanguage("it")}
            className="translation-button__ita">
            IT
          </button>
        </div>
      </div>
      <section className="wrapper">
        <div className="wrapper__title">
          <h1>
            {t("unlock.message")}
            <span className="highlight">{t("businesspotential.message")}</span>
          </h1>
          <p className="wrapper__text">
            <i>{t("wrappertext.message")}</i>{" "}
          </p>
        </div>
        <div className="wrapper__video-container">
          <video
            className="playvideo"
            src={london}
            width="100%"
            // height="300"
            muted
            controls={true}
            autoPlay={true}
            loop={true}
          />
        </div>
      </section>
      <div className="objectives">
        <h1 className="objectives__title-obj">
          {t("sell.message")}{" "}
          <span className="objectives__span">{t("online.message")}</span>
        </h1>
        <ul className="objectives__list">
          <li className="objectives__list-items">{t("monetize.message")}</li>
          <li className="objectives__list-items">{t("expand.message")}</li>
          <li className="objectives__list-items">{t("content.message")}</li>
          <li className="objectives__list-items">{t("handle.message")}</li>
          <li className="objectives__list-items">{t("payment.message")}</li>
        </ul>
      </div>

      <Teaching />
    </>
  );
}

export default Main;

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Main from "../../components/Main/Main";
import Slide from "../../components/Slide/Slide";

function Mainpage() {
  return (
    <>
      <Header />
      <Main />
      <Slide />
      <Footer />
    </>
  );
}
export default Mainpage;

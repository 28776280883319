import "./Teaching.scss";
import world from "../../assets/icons/world.png";
import tech from "../../assets/pictures/technologies.png";
import { useTranslation } from "react-i18next";
// import { Link, Route } from "react-router-dom";

function Teaching() {
  const { t } = useTranslation("global");
  // const handleChangeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  // };

  // function to show up paypal section
  const showPaypalSection = () => {
    const paypal = document.querySelector(".paypal");
    paypal.style.display = "flex";
    paypal.style.animation = "pulse 2s infinite";
    paypal.style.border = "5px solid green";
  };
  return (
    <section id="courses" className="teaching">
      <h1 className="teaching__title">
        {t("learn.message")}
        <br />
        <span className="span-title"> {t("marketplace.message")}</span>
      </h1>

      <section className="top-container">
        <div className="top-container__learning-title">
          <div className="top-container__topic-menu">
            <ul className="top-container__menu-list">
              <li className="top-container__list-item">Development</li>
              <li className="top-container__list-item">
                Programming languages
              </li>
              <li className="top-container__list-item">Javascript</li>
            </ul>
          </div>
          <div className="top-container__box-main-content">
            <h3 className="top-container__teaching-title">
              {t("coaching.message")}
            </h3>
            <p className="top-container__text-content">{t("skills.message")}</p>

            <p className="top-container__text-content">
              {t("digital.message")}
            </p>
            <p className="top-container__text-content">
              <b>{t("ready.message")}</b> {t("schedule.message")}
            </p>
            <p className="top-container__text-content">
              {t("created.message")}{" "}
              <a href="https://www.linkedin.com/in/davideschirru/">
                Davide Schirru
              </a>
            </p>
            <div className="top-container__box-teaching-languages">
              {" "}
              <div>
                <img className="icon-world" src={world} alt="world" />
              </div>
              <p>{t("language.message")}</p>{" "}
            </div>
          </div>
        </div>
        <div className="top-container__intro-explanation">
          <div className="top-container__box-tech-picture">
            {" "}
            <img
              className="technologies-picture"
              src={tech}
              alt="technologies"
            />{" "}
          </div>
        </div>
      </section>
      <section className="botton-container">
        <h2 className="botton-container__title">{t("what.message")} </h2>
        <div className="botton-container__main-content">
          <div>
            <ul className="botton-container__list-arguments">
              <li className="botton-container__item-list-studying">
                ✔️ <b>HTML, CSS, and Sass:</b> {t("build.message")}
              </li>
              <li className="botton-container__item-list-studying">
                ✔️ <b>JavaScript:</b>
                {t("conquer.message")}
              </li>
              <li className="botton-container__item-list-studying">
                ✔️ <b>React: </b> {t("react.message")}
              </li>
            </ul>
          </div>
          {/* <div className="botton-container__main-content"> */}
          <ul className="botton-container__list-arguments">
            <li className="botton-container__item-list-studying">
              ✔️ <b>Node.js:</b> {t("scalable.message")}
            </li>
            <li className="botton-container__item-list-studying">
              ✔️ <b>Express.js:</b> {t("robust.message")}
            </li>
            <li className="botton-container__item-list-studying">
              ✔️ <b>Database:</b> {t("query.message")}
            </li>
            <li className="botton-container__item-list-studying">
              ✔️ <b>Git and GitHub:</b> {t("others.message")}
            </li>
            <li className="botton-container__item-list-studying">
              ✔️ <b>Deployment:</b> {t("deploy.message")}
            </li>
          </ul>
          {/* </div> */}
        </div>
      </section>
      <section className="payment">
        <div className="payment__box">
          <h1 className="payment__title">Make a payment</h1>
          <p className="payment__parag">
            <i>
              Section paypal method: help oomen-webservices to growth
              esponentially. <br />
              <br />
              By choosing the PayPal method, you're not just contributing
              financially; you're becoming a vital part of our journey towards
              innovation and excellence. <br />
              <br />
              Each contribution helps us to scale our services, invest in
              cutting-edge technology, and expand our team to serve you better.
            </i>
          </p>
          <div className="payment__container-button">
            <button
              className="payment__button"
              type="button"
              onClick={showPaypalSection}>
              CROWDFUNDING
            </button>
          </div>
        </div>
        <div className="paypal">
          {/* prendo il codice da paypal creando il bottone  */}
          <form
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_top">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input
              type="hidden"
              name="hosted_button_id"
              value="69A8DK9EFT5HG"
            />
            <table>
              <tr>
                <td>
                  <input type="hidden" name="on0" value="Crowdfounding:" />
                  Crowdfounding:
                </td>
              </tr>
              <tr>
                <td>
                  <select name="os0">
                    <option value="£1 Donation">£1 Donation</option>
                  </select>
                </td>
              </tr>
            </table>
            <input type="hidden" name="currency_code" value="GBP" />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_GB/i/btn/btn_paynowCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Buy Now"
            />
          </form>
        </div>
      </section>
    </section>
  );
}

export default Teaching;

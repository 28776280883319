import "./App.css";
import Mainpage from "./pages/MainPage/Mainpage";
import VideosPage from "./pages/VideosPage/VideosPage";
import Login from "./pages/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Mainpage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/videos" element={<VideosPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Videos from "../../components/Videos/Videos";
function VideosPage() {
  return (
    <div>
      <Header />
      <Videos />
      <Footer />
    </div>
  );
}

export default VideosPage;
